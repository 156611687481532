import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import ThumbGrid from "./thumbnails"
import LightBox from "./lightbox"
import { Grid } from "@material-ui/core"

const GalleryComponent = () => {
  const [showLightbox, setShowLightbox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const handleOpen = i => e => {
    setShowLightbox(true)
    setSelectedImage(i)
  }
  const handleClose = () => {
    setShowLightbox(false)
    setSelectedImage(null)
  }
  const handlePrevRequest = (i, length) => e => {
    setSelectedImage((i - 1 + length) % length)
  }
  const handleNextRequest = (i, length) => e => {
    setSelectedImage((i + 1) % length)
  }

  return (
    <StaticQuery
      query={graphql`
        query allImgQuery {
          source: allFile(
            filter: { relativeDirectory: { eq: "images/gallery" } }
          ) {
            edges {
              node {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
                publicURL
              }
            }
          }
        }
      `}
      render={data => {
        const images = data.source.edges

        return (
          <div className="container mx-auto my-4">
            <Grid container spacing={1} justify="center">
              <ThumbGrid images={images} handleOpen={handleOpen} />

              {showLightbox && selectedImage !== null && (
                <LightBox
                  images={images}
                  handleClose={handleClose}
                  handleNextRequest={handleNextRequest}
                  handlePrevRequest={handlePrevRequest}
                  selectedImage={selectedImage}
                />
              )}
            </Grid>
          </div>
        )
      }}
    />
  )
}

export default GalleryComponent
