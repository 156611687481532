import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Gallery from "../components/gallery"
import SEO from "../components/seo"

const GalleryPage = ({ location, data }) => {
  return (
    <Layout homeLinks location={location}>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <div>
        <h1 className="mt-2 mt-8 mb-0 text-center">Photo Gallery</h1>
        <p className="text-center">
          <a
            href={data.file.publicURL}
            download
            className="mx-auto my-5 bg-coupa-blue coupa-button hover:bg-coupa-blue-light"
          >
            Download Photo Kit
          </a>
        </p>
        <Gallery />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query GalleryQuery {
    allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
    file(extension: { eq: "zip" }) {
      publicURL
    }
  }
`

export default GalleryPage
