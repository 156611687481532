import React from 'react'
//https://github.com/treyhuffine/lightbox-react/blob/master/src/lightbox-react.js
//https://reactjsexample.com/lightbox-for-components-or-images-built-for-react/
import PropTypes from 'prop-types'
import LightboxReact from 'lightbox-react'
import 'lightbox-react/style.css'
import NonStretchedImage from './nonStretchedImage'

const Lightbox = ({
  images,
  selectedImage,
  handleClose,
  handlePrevRequest,
  handleNextRequest
}) => {
  const array = []

  images.forEach((image, index) =>
    array.push(
      <NonStretchedImage key={index} fluid={image.node.childImageSharp.fluid} />
    )
  )

  return (
    <div>
      <LightboxReact
        enableZoom={false}
        clickOutsideToClose={true}
        mainSrc={array[selectedImage]}
        nextSrc={array[(selectedImage + 1) % array.length]}
        prevSrc={array[(selectedImage + array.length - 1) % images.length]}
        onCloseRequest={handleClose}
        onMovePrevRequest={handlePrevRequest(selectedImage, array.length)}
        onMoveNextRequest={handleNextRequest(selectedImage, array.length)}
      />
    </div>
  )
}

Lightbox.propTypes = {
  images: PropTypes.array,
  selectedImage: PropTypes.number,
  handleClose: PropTypes.func,
  handlePrevRequest: PropTypes.func,
  handleNextRequest: PropTypes.func
}

export default Lightbox
