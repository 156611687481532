import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const NonStretchedImage = props => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        // maxHeight: window.innerHeight - 50,
        height: "calc(100vh - 100px)",
        width: "auto",
        margin: "50px auto 0", // Used to center the image
      },
    }
  }

  return <Img style={{ objectFit: "contain" }} {...normalizedProps} />
}

NonStretchedImage.propTypes = {
  props: PropTypes.object,
  fluid: PropTypes.object,
}

export default NonStretchedImage
