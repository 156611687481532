import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { withStyles, ButtonBase, Grid } from "@material-ui/core"

const style = () => ({
  previewButton: {
    display: "inline-block",
    background: "transparent",
    border: "none",
    padding: 0,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 60,
    width: 250,
    height: 300,
    borderRadius: 4,
    boxShadow: "0 0 15px -5px rgba(0,0,0,0.3)"
  },
  thumbnail: {
    width: 250,
    height: 300,
    borderRadius: 4
  }
})

const ThumbGrid = ({ images, handleOpen, classes }) => {
  return images.map((image, i) => (
    <Grid item key={i}>
      <ButtonBase onClick={handleOpen(i)} className={classes.previewButton}>
        <Img
          fluid={image.node.childImageSharp.fluid}
          className={classes.thumbnail}
        />
        <a
          href={image.node.publicURL}
          className="mx-auto my-2 bg-coupa-blue coupa-button hover:bg-coupa-blue-light"
          download
        >
          Download Now
        </a>
      </ButtonBase>
    </Grid>
  ))
}

ThumbGrid.propTypes = {
  classes: PropTypes.object,
  images: PropTypes.array
}

export default withStyles(style)(ThumbGrid)
